@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,400;0,500;0,600;1,300;1,400&display=swap');
@font-face {
  font-family: 'Muli';
  src: url('./assets/fonts/Muli-Light.ttf');
}
@tailwind base;
@tailwind components;
@tailwind utilities;



@layer base {
  * {
    font-family: 'Cormorant Garamond', serif;
  }
}



@layer utilities {
  .bg-custom-gray {
    color: #84847c;
  }
}


/*gallery in academy page*/
#flexbox {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

.column {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.column img {
  vertical-align: middle;
  margin-top: 8px;
  filter: grayscale(70%);
}

.column:nth-child(even) {
  display: flex;
  flex-direction: column-reverse;
}

@media screen and (max-width: 800px) {
  .column {
    flex: 50%;
    max-width: 50%;
  }
}

@media screen and (max-width: 600px) {
  .column {
    flex: 100%;
    max-width: 100%;
  }
}